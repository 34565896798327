import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core"
import { none, Option, some } from "fp-ts/lib/Option"
import * as React from "react"
import Minus from "../icons/Minus"
import Plus from "../icons/Plus"
import Row from "../Row"
import "./custom-accordion.scss"

export type Props<I extends string> = {
  items: [summaryPreText: I, summaryText: string, body: React.ReactNode][]
}

export const accordionItems = <I extends string>(i: Props<I>["items"]) => i

const CustomAccordion = <I extends string>({ items }: Props<I>) => {
  const [open, setOpen] = React.useState<Option<I>>(none)
  const handleChange = (panel: I) => (_: any, isExpanded: boolean) => {
    setOpen(isExpanded ? some(panel) : none)
  }

  return (
    <>
      {items.map(([summaryPreText, summaryText, body]) => {
        const isOpen = open._tag === "Some" && open.value === summaryPreText
        return (
          <Accordion
            key={summaryText}
            className={"gcs-ui-custom-accordion"}
            expanded={isOpen}
            onChange={handleChange(summaryPreText)}
          >
            <AccordionSummary
              expandIcon={isOpen ? <Minus /> : <Plus />}
              aria-controls="content"
            >
              <Row vAlign={"start"}>
                <div className={"gcs-ui-custom-accordion-summary-pre-text"}>
                  {summaryPreText}
                </div>
                <div className={"gcs-ui-custom-accordion-summary-text"}>
                  {summaryText}
                </div>
              </Row>
            </AccordionSummary>

            <AccordionDetails
              style={{ paddingLeft: "45px" }}
              className={"gcs-ui-custom-accordion-paragraph"}
            >
              <Typography variant={"body1"}>{body}</Typography>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

export default CustomAccordion
