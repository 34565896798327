import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import UnderlinedText from "../SpecialText/Underlined"
import InnerSectionLayout from "../InnerSectionLayout"
import "./about.scss"

const AboutSection: React.FC = () => {
  return (
    <InnerSectionLayout
      style={{ marginBottom: "80px" }}
      left={
        <UnderlinedText className={"achernar-ui-about-head"}>
          about us
        </UnderlinedText>
      }
      right={{
        mobileShow: [
          <Grid key={1} item xs={12} md={11}>
            <Typography
              className={"achernar-ui-about-title"}
              variant={"h2"}
              color={"primary"}
            >
              At Global Custodian Solutions, we understand how important it is
              for investors to have a trusted partner in safeguarding and
              servicing their assets.
            </Typography>
          </Grid>,
          <Grid key={2} item xs={12} md={6}>
            <Typography
              className={"achernar-ui-about-paragraph"}
              variant={"body1"}
            >
              From asset managers and investment firms to high-net-worth
              individuals and family offices, Global Custodian Solutions is
              trusted by all nodes of the financial industry.
            </Typography>
          </Grid>,
        ],
        mobileHidden: [
          <Grid key={1} item xs={12} md={6}>
            <Typography
              className={"achernar-ui-about-paragraph"}
              variant={"body1"}
            >
              The expansive custody offering guarantees a safe and segregated
              haven built with transparency and functionality in mind.
            </Typography>
          </Grid>,
          <Grid key={2} item xs={12} md={6}>
            <Typography
              className={"achernar-ui-about-paragraph"}
              variant={"body1"}
            >
              Having a duty of care for clients' financial assets is the
              foundation of our business. We build upon it to go above and
              beyond the industry standards and provide value-added services
              with personalization at the core.
            </Typography>
          </Grid>,
          <Grid key={3} item xs={12} md={6}>
            <Typography
              className={"achernar-ui-about-paragraph"}
              variant={"body1"}
            >
              Over the years, this has allowed us to facilitate several
              multi-billion dollar custodial transactions across multiple
              jurisdictions for our members.
            </Typography>
          </Grid>,
        ],
      }}
      ratio={"1/5"}
    />
  )
}

export default AboutSection
