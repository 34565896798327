import React, { ReactElement } from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import AboutSection from "../components/Sections/About"
import ApproachSection from "../components/Sections/Approach"
import SolutionsSection from "../components/Sections/Solutions"
import ContactSection from "../components/Sections/Contact"
import HeroPartOne from "../components/Sections/HeroPartOne"
import HeroPartTwo from "../components/Sections/HeroPartTwo"
import VideoSection from "../components/Sections/Video"
import { makeStyles, useTheme } from "@material-ui/core"
import Footer from "../components/Footer"
import CookieBanner from "../components/CookieBanner"
import Header from "../components/Header"

const useStyles = makeStyles((theme) => ({
  heroWrapper: {
    minHeight: `calc(100vh - ${theme.constants.mobileHeaderHeight}px)`,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      minHeight: `calc(80vh - ${theme.constants.desktopHeaderHeight}px)`,
    },
    [theme.breakpoints.up("md")]: {
      minHeight: `calc(100vh - ${theme.constants.desktopHeaderHeight}px)`,
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: `calc(80vh - ${theme.constants.desktopHeaderHeight}px)`,
    },
  },
}))

function Index(): ReactElement {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <>
      <Header />
      <Layout>
        <CookieBanner />

        <Section
          id={"intro"}
          className={classes.heroWrapper}
          background={"transparent"}
          noContainer={true}
        >
          <HeroPartOne />
          <HeroPartTwo />
        </Section>
        <Section id={"video"}>
          <VideoSection />
        </Section>
        <Section id={"about"}>
          <AboutSection />
        </Section>
        <Section id={"approach"} background={theme.palette.primary.main}>
          <ApproachSection />
        </Section>
        <Section id={"solutions"}>
          <SolutionsSection />
        </Section>
        <Section id={"contact"} background={theme.palette.secondary.light}>
          <ContactSection />
        </Section>
        <Footer />
      </Layout>
    </>
  )
}

export default Index
