import { Typography } from "@material-ui/core"
import * as React from "react"
import Column from "../Column"
import Hero from "../icons/Hero"
import Row from "../Row"

const ListedText: React.FC = ({ children }) => {
  return (
    <Row vAlign={"start"}>
      <Column style={{ marginTop: "17px", marginRight: "16px" }}>
        <Hero />
      </Column>

      <Typography variant={"h3"} color={"secondary"}>
        {children}
      </Typography>
    </Row>
  )
}

export default ListedText
