import { Container, Grid, Typography } from "@material-ui/core"
import * as React from "react"
import Column from "../Column"
import "./hero-part-one.scss"

const HeroPartOne: React.FC = () => {
  return (
    <Column centered grow>
      <Container>
        <Column className={"gcs-ui-hero-part-one-section"}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <div
                id={"gcs-ui-interceptor-bait"}
                style={{ height: "1px", width: "1px" }}
              />
              <Typography color={"primary"} variant={"h1"}>
                Evolving markets require progressive solutions
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                className={"gcs-ui-hero-part-one-section-description"}
                color={"primary"}
                variant={"body1"}
              >
                Investors nowadays face a myriad of external pressures. Global
                Custodian Solutions supports the strategic goals of its
                qualified members to guide them through the new reality.
              </Typography>
            </Grid>
          </Grid>
        </Column>
      </Container>
    </Column>
  )
}

export default HeroPartOne
