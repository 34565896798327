import React from "react"

function Close({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 27.747 27.747"
    >
      <g data-name="Group 119" transform="translate(-403.353 -688.305)">
        <g data-name="Group 117" transform="rotate(-45 461.223 575.06)">
          <g data-name="Group 91" transform="translate(322.95 620)">
            <g data-name="Group 90" transform="translate(0 11.494)">
              <path
                fill={color ?? "#074d4d"}
                d="M2.71 0h31.844l-2.71 4.684H0z"
                data-name="Path 86"
              ></path>
            </g>
          </g>
        </g>
        <g data-name="Group 118" transform="rotate(45 -627.525 835.04)">
          <g data-name="Group 91">
            <g data-name="Group 90">
              <path
                fill={color ?? "#074d4d"}
                d="M2.71 4.684h31.845L31.844 0H0z"
                data-name="Path 86"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Close
