import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Button,
  useTheme,
  Container,
} from "@material-ui/core"
import * as React from "react"
import cx from "classnames"
import { scrollToId } from "../../../utils/dom"
import { none, Option, some } from "fp-ts/lib/Option"
import "./desktop-header.scss"
import Logo from "../../icons/Logo"
import WhiteLogo from "../../icons/WhiteLogo"
import Column from "../../Column"
import {
  eqSectionOption,
  registerIntersectionObservers,
  Section,
  Variant,
} from "../utils"
import Row from "../../Row"

type Props = {}

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: `${theme.constants.desktopHeaderHeight}px`,
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menuItemsWrapper: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  menuItem: {
    marginRight: "10px",
  },
  menuButton: {
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  logoArea: {
    cursor: "pointer",
  },
}))

const Header: React.FC<Props> = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [activeButton, setActiveButton] = React.useState<Option<Section>>(none)
  const [variant, setVariant] = React.useState<Variant>("big")

  React.useEffect(() => {
    const callback = registerIntersectionObservers({
      setActiveButton,
      setVariant,
      headerHeight: theme.constants.desktopHeaderHeight,
    })
    return callback
  }, [theme.constants.desktopHeaderHeight])

  return (
    <AppBar
      className={cx(classes.appBar, variant, "gcs-ui-desktop-header")}
      position="fixed"
    >
      <Toolbar>
        <Container>
          <Row hAlign={"center"}>
            <Box
              className={classes.logoArea}
              display={"flex"}
              flexDirection={"row"}
              flexGrow={1}
            >
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="home"
                disableRipple
                onClick={() => window.scrollTo({ top: 0 })}
              >
                <Logo />
                <WhiteLogo />
              </IconButton>
            </Box>

            <Row centered className={classes.menuItemsWrapper}>
              <Button
                disableRipple
                className={cx(classes.menuItem, "gcs-ui-menu-item")}
                color="inherit"
                onClick={() => {
                  setActiveButton(some("about"))
                  scrollToId("about", theme.constants.desktopHeaderHeight * 0.7)
                }}
              >
                <Column>
                  <Typography
                    variant={"h5"}
                    style={{ textTransform: "none" }}
                    color={"inherit"}
                  >
                    About us
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("about")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
              <Button
                disableRipple
                className={cx(classes.menuItem, "gcs-ui-menu-item")}
                color="inherit"
                onClick={() => {
                  setActiveButton(some("approach"))
                  scrollToId(
                    "approach",
                    theme.constants.desktopHeaderHeight * 0.7
                  )
                }}
              >
                <Column>
                  <Typography
                    variant={"h5"}
                    style={{ textTransform: "none" }}
                    color={"inherit"}
                  >
                    Our approach
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("approach")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
              <Button
                disableRipple
                className={cx(classes.menuItem, "gcs-ui-menu-item")}
                color="inherit"
                onClick={() => {
                  setActiveButton(some("solutions"))
                  scrollToId(
                    "solutions",
                    theme.constants.desktopHeaderHeight * 0.7
                  )
                }}
              >
                <Column>
                  <Typography
                    variant={"h5"}
                    style={{ textTransform: "none" }}
                    color={"inherit"}
                  >
                    Our solutions
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("solutions")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
              <Button
                disableRipple
                className={cx(classes.menuItem, "gcs-ui-menu-item")}
                color="inherit"
                onClick={() => {
                  setActiveButton(some("contact"))
                  scrollToId(
                    "contact",
                    theme.constants.desktopHeaderHeight * 0.7
                  )
                }}
              >
                <Column>
                  <Typography
                    variant={"h5"}
                    style={{ textTransform: "none" }}
                    color={"inherit"}
                  >
                    Contact us
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("contact")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
            </Row>
          </Row>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Header
