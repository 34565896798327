import React from "react"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Button from "@material-ui/core/Button"
import cx from "classnames"
import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core"
import { scrollToId } from "../../../utils/dom"
import Column from "../../Column"
import {
  eqSectionOption,
  registerIntersectionObservers,
  Section,
  Variant,
} from "../utils"
import { some, none, Option } from "fp-ts/lib/Option"
import "./mobile-header.scss"
import WhiteLogo from "../../icons/WhiteLogo"
import Logo from "../../icons/Logo"
import Row from "../../Row"
import Toggle from "../../icons/Toggle"
import Close from "../../icons/Close"

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: `${theme.constants.mobileHeaderHeight}px`,
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawer: {
    opacity: 0,
    transition: "opacity 0.7s",
  },
  drawerHeaderBig: {
    padding: "30px 0 0 0",
  },
  drawerHeaderSmall: {
    padding: "20px 0 0 24px",
  },
  drawerHeaderActive: {
    opacity: 1,
  },
  menuItem: {
    opacity: 0,
    transform: "none",
    transition: "opacity, transform, .7s",
  },
  menuItemActive: {
    opacity: 1,
    transform: "translateX(-30px)",
  },
  transitionDelay1: {
    transitionDelay: "0s",
  },
  transitionDelay2: {
    transitionDelay: ".2s",
  },
  transitionDelay3: {
    transitionDelay: ".4s",
  },
  transitionDelay4: {
    transitionDelay: ".6s",
  },
}))

const drawerAnimationTime = 600

const MobileHeader = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeButton, setActiveButton] = React.useState<Option<Section>>(none)
  const [variant, setVariant] = React.useState<Variant>("big")
  const [triggerAnimation, setTriggerAnimation] = React.useState(false)
  const toggleDrawer = (open: boolean): React.EventHandler<any> => () => {
    setIsOpen(open)
  }

  React.useEffect(() => {
    setTimeout(() => setTriggerAnimation(isOpen), drawerAnimationTime * 0.5)
  }, [isOpen])

  React.useEffect(() => {
    const callback = registerIntersectionObservers({
      setActiveButton,
      setVariant,
      headerHeight: theme.constants.mobileHeaderHeight,
    })
    return callback
  }, [theme.constants.mobileHeaderHeight])

  return (
    <>
      <AppBar
        className={cx(classes.appBar, "gcs-ui-mobile-header", variant)}
        position="fixed"
      >
        <Toolbar>
          <Row grow>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              disableRipple
              onClick={() => window.scrollTo({ top: 0 })}
            >
              <Logo />
              <WhiteLogo />
            </IconButton>
          </Row>

          <Button disableRipple onClick={toggleDrawer(!isOpen)}>
            {
              <Toggle
                color={variant === "big" ? theme.palette.primary.main : "#FFF"}
              />
            }
          </Button>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        anchor={"right"}
        transitionDuration={{ enter: drawerAnimationTime, exit: 300 }}
      >
        <Column grow>
          <Row
            hAlign={"between"}
            className={cx(classes.drawer, {
              [classes.drawerHeaderBig]: variant === "big",
              [classes.drawerHeaderSmall]: variant === "small",
              [classes.drawerHeaderActive]: triggerAnimation,
            })}
          >
            <Logo color={"#FFF"} />

            <Button disableRipple onClick={toggleDrawer(!isOpen)}>
              {<Close color={"#FFF"} />}
            </Button>
          </Row>

          <Column vAlign={"center"} grow style={{ marginRight: "-25px" }}>
            <Row hAlign={"end"}>
              <Button
                className={cx(classes.menuItem, classes.transitionDelay1, {
                  [classes.menuItemActive]: triggerAnimation,
                })}
                disableRipple
                color="inherit"
                onClick={() => {
                  scrollToId("about", theme.constants.mobileHeaderHeight * 0.7)
                  toggleDrawer(false)(undefined)
                }}
              >
                <Column>
                  <Typography
                    variant={"h3"}
                    style={{
                      marginTop: 0,
                      fontSize: 26,
                      textTransform: "none",
                    }}
                    color={"inherit"}
                  >
                    About us
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("about")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
            </Row>
            <Row hAlign={"end"}>
              <Button
                className={cx(classes.menuItem, classes.transitionDelay2, {
                  [classes.menuItemActive]: triggerAnimation,
                })}
                disableRipple
                color="inherit"
                onClick={() => {
                  scrollToId(
                    "approach",
                    theme.constants.mobileHeaderHeight * 0.7
                  )
                  toggleDrawer(false)(undefined)
                }}
              >
                <Column>
                  <Typography
                    variant={"h3"}
                    style={{
                      marginTop: 40,
                      fontSize: 26,
                      textTransform: "none",
                    }}
                    color={"inherit"}
                  >
                    Our approach
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("approach")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
            </Row>
            <Row hAlign={"end"}>
              <Button
                className={cx(classes.menuItem, classes.transitionDelay3, {
                  [classes.menuItemActive]: triggerAnimation,
                })}
                disableRipple
                color="inherit"
                onClick={() => {
                  scrollToId(
                    "solutions",
                    theme.constants.mobileHeaderHeight * 0.7
                  )
                  toggleDrawer(false)(undefined)
                }}
              >
                <Column>
                  <Typography
                    variant={"h3"}
                    style={{
                      marginTop: 40,
                      fontSize: 26,
                      textTransform: "none",
                    }}
                    color={"inherit"}
                  >
                    Our solutions
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("solutions")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
            </Row>
            <Row hAlign={"end"}>
              <Button
                className={cx(classes.menuItem, classes.transitionDelay4, {
                  [classes.menuItemActive]: triggerAnimation,
                })}
                disableRipple
                color="inherit"
                onClick={() => {
                  scrollToId(
                    "contact",
                    theme.constants.mobileHeaderHeight * 0.7
                  )
                  toggleDrawer(false)(undefined)
                }}
              >
                <Column>
                  <Typography
                    variant={"h3"}
                    style={{
                      marginTop: 40,
                      fontSize: 26,
                      textTransform: "none",
                    }}
                    color={"inherit"}
                  >
                    Contact us
                  </Typography>
                  <div
                    className={cx(
                      {
                        active: eqSectionOption.equals(
                          activeButton,
                          some("contact")
                        ),
                      },
                      "gcs-ui-navigation-link-underline"
                    )}
                  />
                </Column>
              </Button>
            </Row>
          </Column>
        </Column>
      </SwipeableDrawer>
    </>
  )
}

export default MobileHeader
