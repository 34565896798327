import {
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core"
import * as React from "react"
import Column from "../Column"
import Row from "../Row"
import CookieModal from "./CookieModal"
import "./footer.scss"
import PrivacyModal from "./PrivacyModal"

const Footer: React.FC = () => {
  const theme = useTheme()
  const [openCookie, setOpenCookie] = React.useState(false)
  const [openPrivacy, setOpenPrivacy] = React.useState(false)

  return (
    <Row
      className={"gcs-ui-footer"}
      centered
      style={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
      }}
    >
      <CookieModal open={openCookie} handleClose={() => setOpenCookie(false)} />
      <PrivacyModal
        open={openPrivacy}
        handleClose={() => setOpenPrivacy(false)}
      />

      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={2}>
            <Button
              className={"gcs-ui-footer-item"}
              variant={"text"}
              color={"inherit"}
              onClick={() => setOpenCookie(true)}
            >
              <Typography variant={"h6"}>Cookie Policy</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              className={"gcs-ui-footer-item second-column"}
              variant={"text"}
              color={"inherit"}
              onClick={() => setOpenPrivacy(true)}
            >
              <Typography variant={"h6"}>Privacy Policy</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Column
              vAlign={"center"}
              style={{ height: "100%" }}
              className={"gcs-ui-footer-copyright"}
            >
              <Typography variant={"h6"} color={"inherit"}>
                Copyright © 2021 Global Custodian Solutions
              </Typography>
            </Column>
          </Grid>
        </Grid>
      </Container>
    </Row>
  )
}

export default Footer
