import React from "react"

function Plus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 28.284 28.284"
    >
      <g data-name="Group 125" transform="translate(-175.697 -685.541)">
        <g data-name="Group 123" transform="translate(169.95 697.341)">
          <g data-name="Group 89" transform="translate(5.747)">
            <path
              fill="#074d4d"
              d="M2.218 0h26.066l-2.218 4.684H0z"
              data-name="Path 86"
            ></path>
          </g>
        </g>
        <g data-name="Group 124" transform="rotate(90 -243.807 435.988)">
          <g data-name="Group 89" transform="translate(5.747)">
            <path
              fill="#074d4d"
              d="M2.218 0h26.066l-2.218 4.684H0z"
              data-name="Path 86"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Plus
