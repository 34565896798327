import { sequenceT } from "fp-ts/lib/Apply"
import { contramap, eqString } from "fp-ts/lib/Eq"
import {
  chainFirst,
  fromNullable,
  getEq,
  none,
  option,
  some,
  Option,
} from "fp-ts/lib/Option"
import { pipe } from "fp-ts/lib/pipeable"
import { debounce } from "ts-debounce"

export type Section = "about" | "approach" | "solutions" | "contact"

export type Variant = "big" | "small"

export const eqSectionOption = getEq(contramap((s: Section) => s)(eqString))

export const registerIntersectionObservers = ({
  headerHeight,
  setActiveButton,
  setVariant,
}: {
  headerHeight: number
  setActiveButton: React.Dispatch<React.SetStateAction<Option<Section>>>
  setVariant: React.Dispatch<React.SetStateAction<Variant>>
}) => {
  const debouncedSetActive = debounce(
    (sectionId: string) => setActiveButton(some(sectionId as Section)),
    400
  )

  const headerBaitObserver = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        setVariant("big")
      } else {
        setVariant("small")
      }
    },
    { rootMargin: "-100px 0px 0px 0px" }
  )

  const contactsBaitObserver = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        setActiveButton(none)
        setActiveButton(some("contact"))
      }
    },
    { rootMargin: "0px 0px 0px 0px" }
  )

  const scrollDownSectionObserver = new IntersectionObserver(
    ([entry]) => {
      const sectionId = entry.target.id

      if (entry.isIntersecting && entry.boundingClientRect.top > 0) {
        debouncedSetActive(sectionId)
      }
    },
    {
      rootMargin: `0px 0px -${
        window.innerHeight - (headerHeight + 80) * 0.7
      }px 0px`,
    }
  )

  const scrollUpSectionObserver = new IntersectionObserver(
    ([entry]) => {
      const sectionId = entry.target.id

      if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
        debouncedSetActive(sectionId)
      }
    },
    {
      rootMargin: `0px 0px -${
        window.innerHeight - (headerHeight - 20) * 0.7
      }px 0px`,
    }
  )

  pipe(
    fromNullable(document.getElementById("gcs-ui-interceptor-bait")),
    chainFirst((el) => {
      headerBaitObserver.observe(el)
      return none
    })
  )

  pipe(
    fromNullable(document.getElementById("contact")),
    chainFirst((el) => {
      contactsBaitObserver.observe(el)
      return none
    })
  )
  pipe(
    sequenceT(option)(
      fromNullable(document.getElementById("about")),
      fromNullable(document.getElementById("approach")),
      fromNullable(document.getElementById("solutions")),
      fromNullable(document.getElementById("contact"))
    ),
    chainFirst((els) => {
      els.map((el) => scrollDownSectionObserver.observe(el))
      return none
    })
  )
  pipe(
    sequenceT(option)(
      fromNullable(document.getElementById("about")),
      fromNullable(document.getElementById("approach")),
      fromNullable(document.getElementById("solutions")),
      fromNullable(document.getElementById("contact"))
    ),
    chainFirst((els) => {
      els.map((el) => scrollUpSectionObserver.observe(el))
      return none
    })
  )

  return () => {
    scrollDownSectionObserver.disconnect()
    headerBaitObserver.disconnect()
    scrollUpSectionObserver.disconnect()
  }
}
