import React from "react"

function Minus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="4.684"
      viewBox="0 0 28.284 4.684"
    >
      <g data-name="Group 122" transform="translate(-5.747)">
        <g data-name="Group 89" transform="translate(5.747)">
          <path
            fill="#074d4d"
            d="M2.218 0h26.066l-2.218 4.684H0z"
            data-name="Path 86"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Minus
