import React from "react"
function WhiteLogo() {
  return (
    <svg
      className={"gcs-ui-white-logo"}
      xmlns="http://www.w3.org/2000/svg"
      width="37.597"
      height="45.449"
      viewBox="0 0 37.597 45.449"
    >
      <g data-name="Group 74" transform="translate(-83.606 -253.701)">
        <g
          fill="#fff"
          data-name="Group 73"
          transform="translate(83.606 253.701)"
        >
          <path
            d="M139.115 257.134l4.834-3.434H148l-4.834 3.434z"
            data-name="Path 70"
            transform="translate(-110.405 -253.701)"
          ></path>
          <path
            d="M125.238 257.134l4.834-3.434h4.054l-4.834 3.434z"
            data-name="Path 71"
            transform="translate(-103.706 -253.701)"
          ></path>
          <path
            d="M111.36 257.134l4.834-3.434h4.054l-4.834 3.434z"
            data-name="Path 72"
            transform="translate(-97.006 -253.701)"
          ></path>
          <path
            d="M97.484 257.134l4.834-3.434h4.054l-4.834 3.434z"
            data-name="Path 73"
            transform="translate(-90.306 -253.701)"
          ></path>
          <path
            d="M83.606 257.134l4.834-3.434h4.054l-4.834 3.434z"
            data-name="Path 74"
            transform="translate(-83.606 -253.701)"
          ></path>
          <g data-name="Group 72" transform="translate(0 3.434)">
            <path
              d="M139.115 260.34v23.13l4.054-2.027v-21.1z"
              data-name="Path 75"
              transform="translate(-110.406 -260.34)"
            ></path>
            <path
              d="M83.607 283.47l4.054-2.027v-21.1h-4.054z"
              data-name="Path 76"
              transform="translate(-83.607 -260.34)"
            ></path>
            <path
              d="M125.238 298.921l4.054-2.027V260.34h-4.054z"
              data-name="Path 77"
              transform="translate(-103.706 -260.34)"
            ></path>
            <path
              d="M97.484 298.921l4.054-2.027V260.34h-4.054z"
              data-name="Path 78"
              transform="translate(-90.307 -260.34)"
            ></path>
            <path
              d="M111.361 302.355l4.054-2.027V260.34h-4.054z"
              data-name="Path 79"
              transform="translate(-97.006 -260.34)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default WhiteLogo
