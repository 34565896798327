import { Box, Container } from "@material-ui/core"
import * as React from "react"

type Props = {
  id: string
  background?: string
  noContainer?: boolean
  className?: string
}

const Section: React.FC<Props> = ({
  id,
  background,
  noContainer,
  children,
  className,
}) => {
  return (
    <Box
      id={id}
      className={className}
      style={{
        background,
      }}
    >
      {noContainer ? (
        children
      ) : (
        <Container>
          <>{children}</>
        </Container>
      )}
    </Box>
  )
}

export default Section
