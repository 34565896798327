import React from "react"

function Hero() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="6.322"
      viewBox="0 0 64.977 6.322"
    >
      <path
        fill="#f0c37f"
        d="M4.175 0h60.8L60.8 6.322H0z"
        data-name="Path 86"
      ></path>
    </svg>
  )
}

export default Hero
