import cx from "classnames"
import React from "react"
import Player from "@vimeo/player"
import { makeStyles } from "@material-ui/styles"

interface CustomPlayIconProps {
  controlledPlayer: Player | null
  CustomPlayIcon: React.FC
  CustomPauseIcon: React.FC
  playing: boolean
  forceShow: boolean
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = makeStyles(() => ({
  playIcon: {
    minHeight: "30px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "opacity 0.5s",
    opacity: 1,
    cursor: "pointer",
    zIndex: 4,
  },
  playingIcon: {
    opacity: 0,
  },
}))

const CustomPlayIconComponent: React.FC<CustomPlayIconProps> = React.memo(
  ({
    playing,
    setPlaying,
    forceShow,
    controlledPlayer,
    CustomPlayIcon,
    CustomPauseIcon,
  }) => {
    const classes = useStyles()
    React.useEffect(() => {
      controlledPlayer?.on("playing", () => {
        setPlaying(true)
      })
      controlledPlayer?.on("pause", () => {
        setPlaying(false)
      })
    }, [controlledPlayer])

    return (
      <div
        className={cx(classes.playIcon, {
          [classes.playingIcon]: !forceShow && playing,
        })}
      >
        <div
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (playing) {
              controlledPlayer?.pause()
            } else {
              controlledPlayer?.play()
            }
          }}
        />
        {playing ? <CustomPauseIcon /> : <CustomPlayIcon />}
      </div>
    )
  },
  (
    {
      playing: prevPlaying,
      forceShow: prevForceShow,
      controlledPlayer: prevPlayer,
    },
    { playing, forceShow, controlledPlayer }
  ) =>
    prevPlayer === controlledPlayer &&
    prevPlaying === playing &&
    forceShow === prevForceShow
)

export default CustomPlayIconComponent
