import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import InnerSectionLayout from "../InnerSectionLayout"
import UnderlinedText from "../SpecialText/Underlined"
import castleImage from "../../images/castle-web.jpg"
import CustomAccordion, { accordionItems } from "../CustomAccordion"
import Column from "../Column"
import "./solutions.scss"

type Props = {}

const SolutionsSection: React.FC<Props> = () => {
  return (
    <InnerSectionLayout
      style={{ padding: "80px 0" }}
      left={
        <UnderlinedText className={"achernar-ui-solutions-head"}>
          our solutions
        </UnderlinedText>
      }
      right={{
        mobileShow: [
          <Grid key={1} item xs={12}>
            <img
              alt={"our solutions"}
              src={castleImage}
              className="solutions-image"
            />
          </Grid>,
          <Grid key={2} item xs={12} md={6}>
            <Typography
              className={"achernar-ui-solutions-paragraph"}
              variant={"body1"}
              color={"inherit"}
            >
              The financial industry is evolving, and so are the needs of
              high-net-worth individuals, asset managers, family offices, and
              investment companies. To address them exclusively, we provide a
              comprehensive set of custodian solutions and tailor-made services.
            </Typography>
          </Grid>,
          <Grid key={3} item xs={12} md={6}>
            <Typography
              className={"achernar-ui-solutions-paragraph"}
              variant={"body1"}
              color={"inherit"}
            >
              We tackle top-tier members' operational, regulatory, and risk
              mitigation challenges by protecting assets through the whole trade
              cycle - from settlement to safekeeping and tax support.
            </Typography>
          </Grid>,
          <Grid key={4} item xs={12}>
            <Column className={"achernar-ui-solutions-paragraph"}>
              <CustomAccordion items={items} />
            </Column>
          </Grid>,
        ],
      }}
      ratio={"1/5"}
    />
  )
}

const items = accordionItems([
  [
    "01",
    "Settlement of Securities",
    <span>
      Our custody offering empowers a seamless and cost-efficient trade
      settlement. Through the development of customized solutions, we can meet
      the industry’s stringent requirements and appeal to our members' unique
      needs. Our technological edge unlocks a plethora of advantages – from
      simplified delivery and workflow to ensuring the best cut-off times and a
      universe of reporting options.
      <br />
      <br />
      For the purpose of regulatory compliance qualified securities are settled
      or rebooked through our affiliate processing entity Parsimony Limited.
    </span>,
  ],
  [
    "02",
    "Safekeeping of Securities",
    <span>
      The bedrock of our services is ensuring the members' peace of mind. As a
      result, we store securities across a robust network of exchanges all
      across the world. The global footprint allows our members to take
      advantage of the best hand-picked opportunities on the market.
      <br />
      <br />
      Global Custodian Solutions' proven track record is a testimony for our
      members to rest assured that the safekeeping of their securities is
      guaranteed.
    </span>,
  ],
  [
    "03",
    "Notification and Administration",
    <span>
      Our custody services are complemented by innovative monitoring and
      reporting capabilities. The comprehensive notification toolbox covers a
      broad range of assets, including equities, mortgages, and other
      loan-backed investments.
      <br />
      <br />
      The integrated scalable solutions leverage a robust data model that
      provides our members with easy-to-access deep market insights, transparent
      intelligence analytics, and timely information about the performance of
      their portfolio.
    </span>,
  ],
  [
    "04",
    "Security Class Action Service",
    <span>
      Our hands-on approach and reputation are built around the protection of
      our members' best interests. We offer a comprehensive end-to-end global
      security class action service covering fixed-income securities and
      equities across all markets.
      <br />
      <br />
      Global Custodian Solutions takes care of each step of the process. From
      data tracking and litigation research to filing and timely payments for
      our members' claims - all stages are professionally handled by a team of
      securities class action experts.
    </span>,
  ],
  [
    "05",
    "Litigation",
    <span>
      Nowadays, investors worldwide are becoming more active in filing claims.
      By its nature, litigation is outside the course of business-as-usual,
      making it more challenging for financial institutions to take care of.
      <br />
      <br />
      Global Custodian Solutions assists qualified members to deal with
      litigation risk on all fronts. Investment firms can rest assured that
      their business interests are protected and that they can rely on a safe
      shelter, even during times of increased complexity and
      continuously-evolving client demands.
    </span>,
  ],
  [
    "06",
    "Compliance",
    <span>
      Today, a new regulatory update is issued every 7 minutes somewhere around
      the world. Global Custodian Solutions helps qualified members seamlessly
      navigate the dynamic regulatory environment and remain up-to-date with
      active financial legislation.
      <br />
      <br />
      As a 21st-century custodian service provider with a global footprint, we
      combine the traditional "bricks and mortar" of custody with a holistic
      client service approach to ease our international members on the path
      towards their goals. To ensure your peace of mind, we provide smart
      custody solutions in harmony with the compliance landscape in a variety of
      jurisdictions.
    </span>,
  ],
  [
    "07",
    "Tax Support",
    <span>
      The tax support needs of high-net-worth individuals, investment firms,
      asset managers, and family offices are all unique and should be treated as
      such. Partnering with a seasoned custodian with a wealth of experience in
      tax support services ensures that qualified members' individual needs
      would be adequately addressed.
      <br />
      <br />
      Global Custodian Solutions' portfolio of services includes comprehensive
      tax support based on a personal approach and continuous assistance in
      navigating the local and international accounting landscape.
    </span>,
  ],
])

export default SolutionsSection
