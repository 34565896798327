import React from "react"

function Pause() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      viewBox="0 0 79 79"
    >
      <g transform="translate(-.099 -.403)">
        <circle
          cx="39.5"
          cy="39.5"
          r="39.5"
          fill="#fff"
          data-name="Ellipse 1"
          transform="translate(.099 .403)"
        ></circle>
        <g fill="#074d4d" data-name="Group 138">
          <path
            d="M0 0H8V25H0z"
            data-name="Rectangle 312"
            transform="translate(29.099 27.402)"
          ></path>
          <path
            d="M0 0h8v25H0z"
            data-name="Path 104"
            transform="translate(43.099 27.402)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Pause
