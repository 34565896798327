import React from "react"

function Toggle({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="18.283"
      viewBox="0 0 39.05 18.283"
    >
      <g data-name="Group 91" transform="translate(-328.95 -24)">
        <g data-name="Group 89" transform="translate(328.95 24)">
          <path
            fill={color ?? "#074d4d"}
            d="M3.063 0H39.05l-3.063 5.294H0z"
            data-name="Path 86"
          ></path>
        </g>
        <g data-name="Group 90" transform="translate(328.95 36.99)">
          <path
            fill={color ?? "#074d4d"}
            d="M3.063 0H39.05l-3.063 5.294H0z"
            data-name="Path 86"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Toggle
