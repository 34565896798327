import React from "react"

function Play() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      viewBox="0 0 79 79"
    >
      <g transform="translate(-.099 -.403)">
        <circle
          cx="39.5"
          cy="39.5"
          r="39.5"
          fill="#fff"
          data-name="Ellipse 1"
          transform="translate(.099 .403)"
        ></circle>
        <path
          fill="#074d4d"
          d="M12.5 0L25 22H0z"
          data-name="Polygon 1"
          transform="rotate(90 13 40.5)"
        ></path>
      </g>
    </svg>
  )
}

export default Play
