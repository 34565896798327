import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import * as React from "react"
import Column from "../Column"
import ListedText from "../SpecialText/Listed"
import "./hero-part-two.scss"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const HeroPartTwo: React.FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Column className={"gcs-ui-hero-part-two-section"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <ListedText>Security</ListedText>
              <Typography
                className={"gcs-ui-hero-part-two-description"}
                variant={"body1"}
                color={"textSecondary"}
              >
                The cornerstone of custodian service solutions in its modern
                shape.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <ListedText>Discretion</ListedText>
              <Typography
                className={"gcs-ui-hero-part-two-description"}
                variant={"body1"}
                color={"textSecondary"}
              >
                Building trusted and long-lasting partnerships with you at the
                helm.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <ListedText>Value</ListedText>
              <Typography
                className={"gcs-ui-hero-part-two-description"}
                variant={"body1"}
                color={"textSecondary"}
              >
                Bespoke solutions beyond traditional settlement and safekeeping
                of securities.
              </Typography>
            </Grid>
          </Grid>
        </Column>
      </Container>
    </Box>
  )
}

export default HeroPartTwo
