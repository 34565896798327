import React from "react"

function Logo({ color = "#074d4d" }) {
  return (
    <svg
      className={"gcs-ui-logo"}
      xmlns="http://www.w3.org/2000/svg"
      width="175.733"
      height="70.861"
      viewBox="0 0 175.733 60.861"
    >
      <g
        fill={color}
        data-name="Group 74"
        transform="translate(-83.606 -253.701)"
      >
        <g data-name="Group 71" transform="translate(149.218 253.701)">
          <path
            d="M183.217 266.957a4.823 4.823 0 003.561 1.516 4.174 4.174 0 002.936-1.083 4.684 4.684 0 001.444-2.863h-4.62l.842-2.888h7.026v1.42a8.467 8.467 0 01-2.153 5.956 7.161 7.161 0 01-5.523 2.346 8.424 8.424 0 01-3.02-.553 8.172 8.172 0 01-2.61-1.6 7.458 7.458 0 01-1.853-2.731 10.441 10.441 0 010-7.508 7.465 7.465 0 011.853-2.732 8.184 8.184 0 012.61-1.6 8.424 8.424 0 013.02-.553 8.291 8.291 0 013.862.938 7.432 7.432 0 012.923 2.7l-2.695 1.709a4.648 4.648 0 00-4.115-2.455 4.733 4.733 0 00-3.5 1.516 6.778 6.778 0 00.012 8.471z"
            data-name="Path 46"
            transform="translate(-178.404 -253.818)"
          ></path>
          <path
            d="M208.662 271.027h-4.019V253.7h3.177v14.438h1.709z"
            data-name="Path 47"
            transform="translate(-186.426 -253.701)"
          ></path>
          <path
            d="M214.918 271.849a5.745 5.745 0 01-1.9-4.584 6.116 6.116 0 01.95-3.441 5.784 5.784 0 012.383-2.118 7.175 7.175 0 013.092-.674 6.691 6.691 0 014.512 1.648 6.464 6.464 0 01.012 9.169 7.032 7.032 0 01-9.048 0zm6.81-2.117a3.61 3.61 0 00-.012-4.921 3.365 3.365 0 00-4.56 0 3.626 3.626 0 000 4.921 3.414 3.414 0 004.572 0z"
            data-name="Path 48"
            transform="translate(-189 -255.955)"
          ></path>
          <path
            d="M237.736 271.027h-3.177V253.7h3.177v6.69h.168a4.914 4.914 0 013.682-1.612 4.965 4.965 0 013.718 1.66 7.459 7.459 0 01.012 9.157 5.028 5.028 0 01-7.411.037h-.168zm.757-3.549a3.081 3.081 0 004.308 0 3.806 3.806 0 000-4.922 3.052 3.052 0 00-4.308 0 3.806 3.806 0 000 4.922z"
            data-name="Path 49"
            transform="translate(-195.622 -253.701)"
          ></path>
          <path
            d="M255.895 267.132a5.629 5.629 0 013.79-1.119h2.335v-.433a1.8 1.8 0 00-.542-1.42 2 2 0 00-1.383-.481 2.032 2.032 0 00-1.336.445 1.571 1.571 0 00-.541 1.288l-3.153-.506a4.164 4.164 0 011.709-2.851 5.537 5.537 0 013.322-1.023 5.6 5.6 0 013.586 1.2 4.032 4.032 0 011.515 3.345v4.813h1.565l-.866 2.888h-3.1l-.7-1.468h-.168a5 5 0 01-3.8 1.684 3.455 3.455 0 01-2.539-.963 3.39 3.39 0 01-.974-2.527 3.636 3.636 0 011.28-2.872zm5.271 3.081a2.391 2.391 0 00.854-1.793h-2.455q-1.782 0-1.781 1.251 0 1.179 1.492 1.179a2.9 2.9 0 001.889-.637z"
            data-name="Path 50"
            transform="translate(-201.786 -255.955)"
          ></path>
          <path
            d="M278.188 271.027h-4.018V253.7h3.176v14.438h1.709z"
            data-name="Path 51"
            transform="translate(-207.799 -253.701)"
          ></path>
          <path
            d="M193.9 298.351a7.33 7.33 0 01-2.984 3.176 8.491 8.491 0 01-7.208.554 8.174 8.174 0 01-2.61-1.6 7.465 7.465 0 01-1.853-2.731 10.443 10.443 0 010-7.508 7.468 7.468 0 011.853-2.732 8.184 8.184 0 012.61-1.6 8.424 8.424 0 013.02-.553 8.3 8.3 0 013.862.938 7.43 7.43 0 012.923 2.695l-2.695 1.709a4.649 4.649 0 00-4.115-2.455 4.733 4.733 0 00-3.5 1.516 6.8 6.8 0 000 8.471 4.814 4.814 0 006.041.794 4.664 4.664 0 001.792-2.094z"
            data-name="Path 52"
            transform="translate(-178.404 -263.432)"
          ></path>
          <path
            d="M211.291 302.966a4.449 4.449 0 01-1.613 1.407 4.37 4.37 0 01-2.021.493 3.841 3.841 0 01-2.912-1.252 5.141 5.141 0 01-1.179-3.658v-7.339h3.177v7.075a2.337 2.337 0 00.565 1.72 1.938 1.938 0 001.432.565 2.354 2.354 0 001.986-.974 4.976 4.976 0 00.734-3v-5.39h3.176v12.037h-3.176v-1.684z"
            data-name="Path 53"
            transform="translate(-186.095 -265.664)"
          ></path>
          <path
            d="M225.593 300.463a1.5 1.5 0 00.662 1.263 2.763 2.763 0 001.529.4q1.66 0 1.659-.89 0-.434-.673-.783a12.076 12.076 0 00-1.624-.674q-.951-.324-1.9-.758a4.5 4.5 0 01-1.625-1.252 2.936 2.936 0 01-.674-1.925 2.987 2.987 0 011.36-2.6 5.555 5.555 0 013.236-.939 5.674 5.674 0 013.081.83 3.316 3.316 0 011.588 2.3l-3.056.506q-.073-.987-1.612-.987-1.444 0-1.444.794 0 .361.481.649a5.262 5.262 0 001.2.506q.721.217 1.563.565a14.23 14.23 0 011.565.758 3.445 3.445 0 011.2 1.155 3.284 3.284 0 01-.938 4.415 5.841 5.841 0 01-3.392.975 6.11 6.11 0 01-3.513-1.011 3.9 3.9 0 01-1.733-2.863z"
            data-name="Path 54"
            transform="translate(-191.927 -265.568)"
          ></path>
          <path
            d="M240.478 291.336v-2.888h3.176v2.888h3.1l-.841 2.887h-2.263v6.257h2.792l-.866 2.887h-5.1v-9.144h-2.166l.866-2.887z"
            data-name="Path 55"
            transform="translate(-196.776 -264.383)"
          ></path>
          <path
            d="M253.1 303.122a5.746 5.746 0 01-1.9-4.584 6.113 6.113 0 01.95-3.441 5.79 5.79 0 012.383-2.119 7.172 7.172 0 013.092-.673 6.693 6.693 0 014.512 1.648 6.463 6.463 0 01.012 9.168 7.032 7.032 0 01-9.048 0zm6.81-2.118a3.61 3.61 0 00-.012-4.921 3.364 3.364 0 00-4.56 0 3.626 3.626 0 000 4.921 3.414 3.414 0 004.572 0z"
            data-name="Path 56"
            transform="translate(-200.739 -265.568)"
          ></path>
          <path
            d="M281.126 300.9h-.168a4.84 4.84 0 01-3.658 1.612 5 5 0 01-3.742-1.648 7.436 7.436 0 010-9.156 4.987 4.987 0 013.742-1.661 4.84 4.84 0 013.658 1.612h.168v-6.69h3.174V302.3h-3.177zm-.758-7.075a3.034 3.034 0 00-4.295 0 3.768 3.768 0 000 4.921 3.064 3.064 0 004.295 0 3.805 3.805 0 000-4.921z"
            data-name="Path 57"
            transform="translate(-207.126 -263.315)"
          ></path>
          <path
            d="M292.618 290.247h3.995v12.032h-3.177v-9.144h-1.684zm.938-4.741a1.866 1.866 0 011.4-.6 1.9 1.9 0 011.408.6 1.99 1.99 0 01.589 1.443 1.961 1.961 0 01-.589 1.42 1.948 1.948 0 01-2.8.013 1.973 1.973 0 01-.577-1.432 2.012 2.012 0 01.57-1.444z"
            data-name="Path 58"
            transform="translate(-213.204 -263.293)"
          ></path>
          <path
            d="M303.081 298.4a5.631 5.631 0 013.79-1.119h2.334v-.433a1.8 1.8 0 00-.541-1.42 2.008 2.008 0 00-1.384-.481 2.034 2.034 0 00-1.335.445 1.569 1.569 0 00-.542 1.288l-3.153-.506a4.16 4.16 0 011.709-2.851 5.53 5.53 0 013.321-1.023 5.6 5.6 0 013.585 1.2 4.032 4.032 0 011.516 3.345v4.813h1.565l-.866 2.887h-3.1l-.7-1.468h-.168a4.993 4.993 0 01-3.8 1.684 3.46 3.46 0 01-2.539-.962 3.4 3.4 0 01-.974-2.527 3.64 3.64 0 011.282-2.872zm5.271 3.081a2.386 2.386 0 00.853-1.792h-2.454q-1.782 0-1.781 1.251 0 1.18 1.491 1.179a2.9 2.9 0 001.891-.634z"
            data-name="Path 59"
            transform="translate(-216.291 -265.568)"
          ></path>
          <path
            d="M324.7 294.206a4.449 4.449 0 011.612-1.408 4.37 4.37 0 012.021-.493 3.842 3.842 0 012.912 1.252 5.139 5.139 0 011.179 3.658v7.339h-3.176v-7.074a2.343 2.343 0 00-.565-1.721 1.944 1.944 0 00-1.432-.566 2.353 2.353 0 00-1.985.975 4.974 4.974 0 00-.734 3v5.39h-3.177v-12.036h3.177v1.684z"
            data-name="Path 60"
            transform="translate(-222.305 -265.568)"
          ></path>
          <path
            d="M181.516 328.083a2.669 2.669 0 001.071 2.178 3.84 3.84 0 002.346.758 3.422 3.422 0 001.865-.494 1.512 1.512 0 00.782-1.335 1.779 1.779 0 00-.662-1.36 4.6 4.6 0 00-1.637-.914q-.973-.325-2.129-.783a22.945 22.945 0 01-2.13-.962 4.427 4.427 0 01-1.637-1.481 3.988 3.988 0 01-.662-2.3 4.111 4.111 0 011.709-3.5 7.117 7.117 0 017.917-.12 4.368 4.368 0 011.925 3.164l-3.153.458a1.712 1.712 0 00-.89-1.385 3.568 3.568 0 00-1.877-.492 3.309 3.309 0 00-1.733.433 1.36 1.36 0 00-.722 1.227 1.6 1.6 0 00.915 1.335 10.573 10.573 0 002.213 1q1.3.434 2.6 1.01a5.916 5.916 0 012.215 1.709 4.228 4.228 0 01.914 2.743 4.315 4.315 0 01-1.7 3.634 6.548 6.548 0 01-4.1 1.3 7.313 7.313 0 01-4.464-1.432 5.243 5.243 0 01-2.153-3.959z"
            data-name="Path 61"
            transform="translate(-178.34 -273.045)"
          ></path>
          <path
            d="M200.393 334.394a5.745 5.745 0 01-1.9-4.584 6.114 6.114 0 01.95-3.441 5.784 5.784 0 012.383-2.118 7.175 7.175 0 013.092-.674 6.693 6.693 0 014.512 1.648 6.462 6.462 0 01.012 9.168 7.032 7.032 0 01-9.048 0zm6.81-2.117a3.61 3.61 0 00-.012-4.922 3.364 3.364 0 00-4.56 0 3.626 3.626 0 000 4.922 3.414 3.414 0 004.572 0z"
            data-name="Path 62"
            transform="translate(-184.535 -275.182)"
          ></path>
          <path
            d="M224.054 333.572h-4.018v-17.327h3.177v14.439h1.708z"
            data-name="Path 63"
            transform="translate(-191.158 -272.928)"
          ></path>
          <path
            d="M236.9 334.238a4.449 4.449 0 01-1.613 1.407 4.374 4.374 0 01-2.022.493 3.841 3.841 0 01-2.912-1.252 5.144 5.144 0 01-1.179-3.658v-7.338h3.176v7.075a2.338 2.338 0 00.566 1.72 1.938 1.938 0 001.432.566 2.357 2.357 0 001.986-.974 4.981 4.981 0 00.734-3v-5.39h3.176v12.032h-3.176v-1.684z"
            data-name="Path 64"
            transform="translate(-193.967 -275.278)"
          ></path>
          <path
            d="M249.338 322.608v-2.888h3.176v2.888h3.1l-.842 2.887h-2.262v6.257h2.792l-.866 2.888h-5.1v-9.14h-2.166l.866-2.887z"
            data-name="Path 65"
            transform="translate(-199.5 -273.996)"
          ></path>
          <path
            d="M260.616 321.519h3.994v12.032h-3.177v-9.145h-1.683zm.938-4.741a1.864 1.864 0 011.4-.6 1.9 1.9 0 011.408.6 1.987 1.987 0 01.589 1.444 1.96 1.96 0 01-.589 1.42 1.9 1.9 0 01-1.408.6 1.881 1.881 0 01-1.4-.59 1.97 1.97 0 01-.578-1.432 2.011 2.011 0 01.579-1.442z"
            data-name="Path 66"
            transform="translate(-203.366 -272.906)"
          ></path>
          <path
            d="M271.657 334.394a5.745 5.745 0 01-1.9-4.584 6.114 6.114 0 01.95-3.441 5.784 5.784 0 012.383-2.118 7.175 7.175 0 013.092-.674 6.693 6.693 0 014.512 1.648 6.462 6.462 0 01.012 9.168 7.032 7.032 0 01-9.048 0zm6.81-2.117a3.61 3.61 0 00-.012-4.922 3.364 3.364 0 00-4.56 0 3.626 3.626 0 000 4.922 3.414 3.414 0 004.572 0z"
            data-name="Path 67"
            transform="translate(-206.442 -275.182)"
          ></path>
          <path
            d="M294.645 325.478a4.441 4.441 0 011.612-1.407 4.371 4.371 0 012.021-.494 3.842 3.842 0 012.912 1.251 5.139 5.139 0 011.179 3.658v7.34h-3.176v-7.075a2.343 2.343 0 00-.566-1.721 1.943 1.943 0 00-1.432-.566 2.356 2.356 0 00-1.986.975 4.976 4.976 0 00-.733 3v5.39H291.3v-12.035h3.177v1.684z"
            data-name="Path 68"
            transform="translate(-213.065 -275.182)"
          ></path>
          <path
            d="M313.327 331.735a1.5 1.5 0 00.662 1.264 2.762 2.762 0 001.528.4q1.661 0 1.66-.89c0-.289-.224-.55-.674-.783a12.183 12.183 0 00-1.623-.674q-.951-.324-1.9-.757a4.506 4.506 0 01-1.625-1.252 2.936 2.936 0 01-.674-1.925 2.988 2.988 0 011.36-2.6 5.555 5.555 0 013.236-.938 5.674 5.674 0 013.081.83 3.314 3.314 0 011.587 2.3l-3.056.5q-.073-.987-1.612-.987-1.444 0-1.445.794 0 .36.482.65a5.311 5.311 0 001.2.5 16.518 16.518 0 011.564.566 14.15 14.15 0 011.565.758 3.455 3.455 0 011.2 1.155 3.285 3.285 0 01-.939 4.416 5.841 5.841 0 01-3.393.975 6.114 6.114 0 01-3.511-1.005 3.9 3.9 0 01-1.733-2.863z"
            data-name="Path 69"
            transform="translate(-218.897 -275.182)"
          ></path>
        </g>
        <g data-name="Group 73" transform="translate(83.606 253.701)">
          <path
            d="M139.115 258.3l6.474-4.6h5.428l-6.474 4.6z"
            data-name="Path 70"
            transform="translate(-100.67 -253.701)"
          ></path>
          <path
            d="M125.238 258.3l6.474-4.6h5.428l-6.474 4.6z"
            data-name="Path 71"
            transform="translate(-96.404 -253.701)"
          ></path>
          <path
            d="M111.36 258.3l6.474-4.6h5.428l-6.474 4.6z"
            data-name="Path 72"
            transform="translate(-92.138 -253.701)"
          ></path>
          <path
            d="M97.484 258.3l6.474-4.6h5.428l-6.474 4.6z"
            data-name="Path 73"
            transform="translate(-87.872 -253.701)"
          ></path>
          <path
            d="M83.606 258.3l6.474-4.6h5.428l-6.474 4.6z"
            data-name="Path 74"
            transform="translate(-83.606 -253.701)"
          ></path>
          <g data-name="Group 72" transform="translate(.001 4.598)">
            <path
              d="M139.115 260.34v30.974l5.428-2.714v-28.26z"
              data-name="Path 75"
              transform="translate(-100.671 -260.34)"
            ></path>
            <path
              d="M83.607 291.313l5.428-2.714V260.34h-5.428z"
              data-name="Path 76"
              transform="translate(-83.607 -260.34)"
            ></path>
            <path
              d="M125.238 312l5.428-2.714V260.34h-5.428z"
              data-name="Path 77"
              transform="translate(-96.405 -260.34)"
            ></path>
            <path
              d="M97.484 312l5.428-2.714V260.34h-5.428z"
              data-name="Path 78"
              transform="translate(-87.873 -260.34)"
            ></path>
            <path
              d="M111.361 316.6l5.428-2.714V260.34h-5.428z"
              data-name="Path 79"
              transform="translate(-92.139 -260.34)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
