import * as React from "react"
import Column from "../Column"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Video from "../VimeoVideo"
import Pause from "../icons/Pause"
import Play from "../icons/Play"
import "./video.scss"

const VideoSection: React.FC = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  return isDesktop ? (
    <Column className={"gcs-ui-video-section"}>
      <Video
        videoURL={"https://player.vimeo.com/video/491965745"}
        heightWidthRatio={9 / 16}
        controls={true}
        CustomPlayIcon={Play}
        CustomPauseIcon={Pause}
      />
    </Column>
  ) : (
    <Column className={"gcs-ui-video-section"}>
      <Video
        videoURL={"https://player.vimeo.com/video/491965779"}
        heightWidthRatio={16 / 9}
        controls={true}
        CustomPlayIcon={Play}
        CustomPauseIcon={Pause}
      />
    </Column>
  )
}

export default VideoSection
