import { Grid, Typography, useTheme } from "@material-ui/core"
import * as React from "react"
import Column from "../Column"
import InnerSectionLayout from "../InnerSectionLayout"
import UnderlinedText from "../SpecialText/Underlined"
import "./contact.scss"

type Props = {}

const ContactSection: React.FC<Props> = () => {
  const theme = useTheme()

  return (
    <InnerSectionLayout
      color={theme.palette.primary.main}
      style={{
        padding: "80px 0 40px",
      }}
      left={
        <Column>
          <UnderlinedText className={"achernar-ui-contact-head"}>
            contact us
          </UnderlinedText>

          <Grid key={1} item xs={12} md={10}>
            <Typography
              className={"achernar-ui-contact-title"}
              variant={"h3"}
              color={"inherit"}
            >
              For our qualified members, we are delighted to discuss how we can
              help achieve your financial goals.
            </Typography>
          </Grid>
        </Column>
      }
      right={{
        mobileShow: [
          <Grid key={1} item xs={12} md={4} />,
          <Grid key={2} item xs={12} md={8}>
            <Typography
              className={"achernar-ui-contact-paragraph-title"}
              variant={"h4"}
              color={"inherit"}
            >
              GUERNSEY
            </Typography>
            <Typography
              className={"achernar-ui-contact-paragraph"}
              variant={"body1"}
              color={"textPrimary"}
            >
              55 Mount Row, St Peter Port,
              <br />
              Guernsey, GY11 NU (CI)
            </Typography>
          </Grid>,
          <Grid key={3} item xs={12} md={4} />,
          <Grid key={4} item xs={12} md={8}>
            <Typography
              className={"achernar-ui-contact-paragraph-title"}
              variant={"h4"}
              color={"inherit"}
            >
              United Kingdom
            </Typography>
            <Typography
              className={"achernar-ui-contact-paragraph"}
              variant={"body1"}
              color={"textPrimary"}
            >
              Building 4, Imperial Place,
              <br />
              Elstree Way, Borehamwood,
              <br />
              Herts, WD6 IJN, UK
            </Typography>
          </Grid>,
          <Grid key={5} item xs={12} md={4} />,
          <Grid key={6} item xs={12} md={8}>
            <Typography
              className={"achernar-ui-contact-paragraph-title"}
              variant={"h4"}
              color={"inherit"}
            >
              contact
            </Typography>
            <Typography
              className={"achernar-ui-contact-paragraph noselect"}
              variant={"body1"}
              color={"textPrimary"}
              noWrap
            >
              +44 (0) 20 3968 4601
              <br />
              info@globalcustodiansolutions.com
            </Typography>
          </Grid>,
        ],
      }}
      ratio={"1/1"}
    />
  )
}

export default ContactSection
