import * as React from "react"
import cx from "classnames"
import "./row.scss"
import { noop } from "../../utils/function"

interface Props {
  centered?: boolean
  grow?: boolean
  className?: string
  onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement>>
  hAlign?: "start" | "end" | "between" | "evenly" | "around" | "center"
  vAlign?: "start" | "end" | "center"
  style?: React.CSSProperties
}

const Row: React.FC<Props> = ({
  className,
  hAlign,
  vAlign,
  grow,
  centered,
  children,
  style,
  onClick = noop,
}) => (
  <div
    style={style}
    onClick={(e) => {
      onClick(e)
    }}
    className={cx("gcs-ui-row", className, {
      centered,
      grow,
      "h-center": hAlign === "center",
      "h-start": hAlign === "start",
      "h-end": hAlign === "end",
      "h-between": hAlign === "between",
      "h-evenly": hAlign === "evenly",
      "h-around": hAlign === "around",
      "v-start": vAlign === "start",
      "v-end": vAlign === "end",
      "v-center": vAlign === "center",
    })}
  >
    {children}
  </div>
)

export default Row
