import React from "react"

function ArrowDown({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.491"
      height="6.407"
      viewBox="0 0 10.491 6.407"
    >
      <g data-name="Group 112" transform="translate(-126.531 -2545.301)">
        <g
          fill={color ?? "#074d4d"}
          data-name="Group 111"
          transform="translate(126.531 2545.301)"
        >
          <path
            d="M0 0H7.383V1.678H0z"
            data-name="Rectangle 276"
            transform="rotate(135 5 2.766)"
          ></path>
          <path
            d="M0 0H7.383V1.678H0z"
            data-name="Rectangle 305"
            transform="rotate(45 .593 1.432)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default ArrowDown
