import { Grid, Typography, useTheme } from "@material-ui/core"
import * as React from "react"
import Column from "../Column"
import InnerSectionLayout from "../InnerSectionLayout"
import UnderlinedText from "../SpecialText/Underlined"
import "./approach.scss"

type Props = {}

const ApproachSection: React.FC<Props> = () => {
  const theme = useTheme()
  return (
    <InnerSectionLayout
      color={theme.palette.secondary.light}
      style={{ padding: "80px 0 40px" }}
      left={
        <Column>
          <UnderlinedText
            color={theme.palette.secondary.light}
            className={"achernar-ui-approach-head"}
          >
            our approach
          </UnderlinedText>

          <Grid key={1} item xs={12} md={10}>
            <Typography
              className={"achernar-ui-approach-title"}
              variant={"h2"}
              color={"inherit"}
            >
              In a world of growing complexity, investors find it harder than
              ever to have their custody needs adequately addressed. In search
              of solutions, they risk getting farther away from their goals.
            </Typography>
          </Grid>
        </Column>
      }
      right={{
        mobileShow: [
          <Grid key={2} item xs={12} lg={6}>
            <Typography
              className={"achernar-ui-approach-paragraph"}
              variant={"body1"}
              color={"inherit"}
            >
              Global Custodian Solutions partners with qualified members and
              their advisors to design bespoke custody services tailored to
              their unique requirements.
            </Typography>
            <Typography
              className={"achernar-ui-approach-paragraph"}
              variant={"body1"}
              color={"inherit"}
            >
              Our strive to continually move up the value chain by focusing on
              our members' front- and middle-office needs, alongside the
              proactive and client-centered approach, makes us the one-stop-shop
              of custody services.
            </Typography>
          </Grid>,
        ],
        mobileHidden: [
          <Grid key={2} item xs={12} lg={6}>
            <Typography
              className={"achernar-ui-approach-paragraph"}
              variant={"body1"}
              color={"inherit"}
            >
              Capitalizing on Global Custodian Solutions' extensive experience,
              dedication, and first-class service, allows qualified members to
              maintain their competitive position and focus on what matters the
              most - the wellbeing and expansion of their business.
            </Typography>
          </Grid>,
        ],
      }}
      ratio={"1/1"}
    />
  )
}

export default ApproachSection
