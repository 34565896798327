import {
  Backdrop,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core"
import * as React from "react"
import Close from "../icons/Close"
import Row from "../Row"
import UnderlinedText from "../SpecialText/Underlined"

type Props = {
  open: boolean
  handleClose: () => void
}

const PrivacyModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Dialog
      className={"gcs-ui-modal"}
      open={open}
      maxWidth={"lg"}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        <Row hAlign={"between"} vAlign={"center"} style={{ height: "100%" }}>
          <UnderlinedText>Privacy Policy</UnderlinedText>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Row>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: 60 }}>
        <Typography variant={"body1"}>
          Services are provided exclusively to our qualified members and
          partners on a cost-sharing basis.
          <br />
          <br />
          GCS does not provide trade execution or take market risk.
          <br />
          <br />
          Effective date: December 17, 2018
          <br />
          <br />
          Global Custodian Solutions Limited ("us", "we", or "our") operates the
          globalcustodiansolutions.com website (hereinafter referred to as the
          "Service").
          <br />
          <br />
          This page informs you of our policies regarding the collection, use
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
          <br />
          <br />
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions, accessible from
          globalcustodiansolutions.com
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Definitions
        </Typography>

        <ul>
          <li>
            <Typography style={{ fontWeight: 600 }}>Service</Typography>
            <Typography variant={"body1"}>
              Service is the globalcustodiansolutions.com website operated by
              Global Custodian Solutions Limited.
            </Typography>
          </li>
          <li>
            <Typography style={{ fontWeight: 600 }}>Personal Data</Typography>
            <Typography variant={"body1"}>
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </Typography>
          </li>

          <li>
            <Typography style={{ fontWeight: 600 }}>Usage Data</Typography>
            <Typography variant={"body1"}>
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </Typography>
          </li>

          <li>
            <Typography style={{ fontWeight: 600 }}>Cookies</Typography>
            <Typography variant={"body1"}>
              Cookies are small files stored on your device (computer or mobile
              device).
            </Typography>
          </li>

          <li>
            <Typography style={{ fontWeight: 600 }}>Data Controller</Typography>
            <Typography variant={"body1"}>
              Data Controller means the natural or legal person who (either
              alone or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal
              information are, or are to be, processed. For the purpose of this
              Privacy Policy, we are a Data Controller of your Personal Data.
            </Typography>
          </li>

          <li>
            <Typography style={{ fontWeight: 600 }}>
              Data Processors (or Service Providers)
            </Typography>
            <Typography variant={"body1"}>
              Data Processor (or Service Provider) means any natural or legal
              person who processes the data on behalf of the Data Controller. We
              may use the services of various Service Providers in order to
              process your data more effectively.
            </Typography>
          </li>

          <li>
            <Typography style={{ fontWeight: 600 }}>
              Data Subject (or User)
            </Typography>
            <Typography variant={"body1"}>
              Data Subject is any living individual who is using our Service and
              is the subject of Personal Data.
            </Typography>
          </li>
        </ul>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Information Collection and Use
        </Typography>

        <Typography variant={"body1"}>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Types of Data Collected
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Personal Data
        </Typography>

        <Typography variant={"body1"}>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally identifiable information
          may include, but is not limited to:
        </Typography>

        <ul>
          <li>
            <Typography variant={"body1"}>Email address</Typography>
          </li>

          <li>
            <Typography variant={"body1"}>First name and last name</Typography>
          </li>

          <li>
            <Typography variant={"body1"}>Cookies and Usage Data</Typography>
          </li>
        </ul>

        <Typography variant={"body1"}>
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by contacting us.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Usage Data
        </Typography>
        <Typography variant={"body1"}>
          We may also collect information on how the Service is accessed and
          used (“Usage Data”). This Usage Data may include information such as
          your computer’s Internet Protocol address (e.g. IP address), browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique
          device identifiers and other diagnostic data.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Tracking & Cookies Data
        </Typography>

        <Typography variant={"body1"}>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
          <br />
          <br />
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyse our Service.
          <br />
          <br />
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
          <br />
          <br />
          Examples of Cookies we use:
        </Typography>
        <ul>
          <li>
            <Typography variant={"body1"}>
              <strong>Session Cookies.</strong> We use Session Cookies to
              operate our Service.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              <strong>Preference Cookies.</strong> We use Preference Cookies to
              remember your preferences and various settings.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              <strong>Security Cookies.</strong> We use Security Cookies for
              security purposes.
            </Typography>
          </li>
        </ul>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Use of Data
        </Typography>
        <Typography variant={"body1"}>
          Global Custodian Solutions Limited uses the collected data for various
          purposes:
        </Typography>

        <ul>
          <li>
            <Typography variant={"body1"}>
              To provide and maintain our Service
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              To notify you about changes to our Service
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              To provide customer support
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              To gather analysis or valuable information so that we can improve
              our Service
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              To monitor the usage of our Service
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              To detect, prevent and address technical issues
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information
            </Typography>
          </li>
        </ul>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Legal Basis for Processing Personal Data under the General Data
          Protection Regulation (GDPR)
        </Typography>
        <Typography variant={"body1"}>
          If you are from the European Economic Area (EEA), Global Custodian
          Solutions Limited legal basis for collecting and using the personal
          information described in this Privacy Policy depends on the Personal
          Data we collect and the specific context in which we collect it.
          <br />
          <br />
          Global Custodian Solutions Limited may process your Personal Data
          because:
        </Typography>

        <ul>
          <li>
            <Typography variant={"body1"}>
              We need to perform a contract with you
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              You have given us permission to do so
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              The processing is in our legitimate interests and it is not
              overridden by your rights
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>To comply with the law</Typography>
          </li>
        </ul>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Retention of Data
        </Typography>
        <Typography variant={"body1"}>
          Global Custodian Solutions Limited will retain your Personal Data only
          for as long as is necessary for the purposes set out in this Privacy
          Policy. We will retain and use your Personal Data to the extent
          necessary to comply with our legal obligations (for example, if we are
          required to retain your data to comply with applicable laws), resolve
          disputes and enforce our legal agreements and policies.
          <br />
          <br />
          Global Custodian Solutions Limited will also retain Usage Data for
          internal analysis purposes. Usage Data is generally retained for a
          shorter period of time, except when this data is used to strengthen
          the security or to improve the functionality of our Service, or we are
          legally obligated to retain this data for longer periods.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Transfer of Data
        </Typography>
        <Typography variant={"body1"}>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
          <br />
          <br />
          If you are located outside United Kingdom and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United Kingdom and process it there.
          <br />
          <br />
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
          <br />
          <br />
          Global Custodian Solutions Limited will take all the steps reasonably
          necessary to ensure that your data is treated securely and in
          accordance with this Privacy Policy and no transfer of your Personal
          Data will take place to an organisation or a country unless there are
          adequate controls in place including the security of your data and
          other personal information.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Disclosure of Data
        </Typography>
        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Disclosure for Law Enforcement
        </Typography>
        <Typography variant={"body1"}>
          Under certain circumstances, Global Custodian Solutions Limited may be
          required to disclose your Personal Data if required to do so by law or
          in response to valid requests by public authorities (e.g. a court or a
          government agency).
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Legal Requirements
        </Typography>
        <Typography variant={"body1"}>
          Global Custodian Solutions Limited may disclose your Personal Data in
          the good faith belief that such action is necessary to:
        </Typography>

        <ul>
          <li>
            <Typography variant={"body1"}>
              To comply with a legal obligation
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              To protect and defend the rights or property of Global Custodian
              Solutions Limited
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              To protect the personal safety of users of the Service or the
              public
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              To protect against legal liability
            </Typography>
          </li>
        </ul>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Security of Data
        </Typography>
        <Typography variant={"body1"}>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Our Policy on “Do Not Track” Signals under the California Online
          Protection Act (CalOPPA)
        </Typography>
        <Typography variant={"body1"}>
          We do not support Do Not Track (“DNT”). Do Not Track is a preference
          you can set in your web browser to inform websites that you do not
          want to be tracked.
          <br />
          <br />
          You can enable or disable Do Not Track by visiting the Preferences or
          Settings page of your web browser.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Your Data Protection Rights under the General Data Protection
          Regulation (GDPR)
        </Typography>
        <Typography variant={"body1"}>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. Global Custodian Solutions Limited
          aims to take reasonable steps to allow you to correct, amend, delete
          or limit the use of your Personal Data.
          <br />
          <br />
          If you wish to be informed about what Personal Data we hold about you
          and if you want it to be removed from our systems, please contact us.
          <br />
          <br />
          In certain circumstances, you have the following data protection
          rights:
        </Typography>

        <ul>
          <li>
            <Typography variant={"body1"}>
              The right to access, update or delete the information we have on
              you. Whenever made possible, you can access, update or request
              deletion of your Personal Data directly within your account
              settings section. If you are unable to perform these actions
              yourself, please contact us to assist you.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              The right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              The right to object. You have the right to object to our
              processing of your Personal Data.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              The right of restriction. You have the right to request that we
              restrict the processing of your personal information.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              The right to data portability. You have the right to be provided
              with a copy of the information we have on you in a structured,
              machine-readable and commonly used format.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              The right to withdraw consent. You also have the right to withdraw
              your consent at any time where Global Custodian Solutions Limited
              relied on your consent to process your personal information.
            </Typography>
          </li>
        </ul>

        <Typography variant={"body1"}>
          Please note that we may ask you to verify your identity before
          responding to such requests.
          <br />
          <br />
          You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Data. For more information,
          please contact your local data protection authority in the European
          Economic Area (EEA).
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Service Providers
        </Typography>
        <Typography variant={"body1"}>
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), provide the Service on our behalf,
          perform Service-related services or assist us in analysing how our
          Service is used.
          <br />
          <br />
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Analytics
        </Typography>
        <Typography variant={"body1"}>
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.
          <ul>
            <li>
              Google Analytics
              <br />
              <br />
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network.
              <br />
              <br />
              You can opt-out of having made your activity on the Service
              available to Google Analytics by installing the Google Analytics
              opt-out browser add-on. The add-on prevents the Google Analytics
              JavaScript (ga.js, analytics.js and dc.js) from sharing
              information with Google Analytics about visits activity.
              <br />
              <br />
              For more information on the privacy practices of Google, please
              visit the Google Privacy & Terms web page:
              https://policies.google.com/privacy?hl=en
            </li>
          </ul>
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Links to Other Sites
        </Typography>
        <Typography variant={"body1"}>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit.
          <br />
          <br />
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Children’s Privacy
        </Typography>
        <Typography variant={"body1"}>
          Our Service does not address anyone under the age of 18 (“Children”).
          <br />
          <br />
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Child has provided us with Personal Data, please
          contact us. If we become aware that we have collected Personal Data
          from children without verification of parental consent, we take steps
          to remove that information from our servers.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant={"body1"}>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
          <br />
          <br />
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          “effective date” at the top of this Privacy Policy.
          <br />
          <br />
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Contact Us
        </Typography>
        <Typography variant={"body1"}>
          If you have any questions about this Privacy Policy, please contact
          us:
        </Typography>

        <ul>
          <li>
            <Typography variant={"body1"}>
              By email: info@globalcustodiansolutions.com
            </Typography>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  )
}

export default PrivacyModal
