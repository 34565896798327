import {
  Backdrop,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core"
import * as React from "react"
import Close from "../icons/Close"
import Row from "../Row"
import UnderlinedText from "../SpecialText/Underlined"

type Props = {
  open: boolean
  handleClose: () => void
}

const CookieModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={"gcs-ui-modal"}
      open={open}
      maxWidth={"lg"}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        <Row hAlign={"between"} vAlign={"center"} style={{ height: "100%" }}>
          <UnderlinedText>Cookie Policy</UnderlinedText>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Row>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          What Are Cookies
        </Typography>

        <Typography variant={"body1"}>
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          How We Use Cookies
        </Typography>

        <Typography variant={"body1"}>
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Disabling Cookies
        </Typography>

        <Typography variant={"body1"}>
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the this site.
          Therefore it is recommended that you do not disable cookies.
        </Typography>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          The Cookies We Set
        </Typography>

        <ul>
          <li>
            <Typography style={{ fontWeight: 600 }}>
              Forms related cookies
            </Typography>
            <Typography variant={"body1"}>
              When you submit data to through a form such as those found on
              contact pages or comment forms cookies may be set to remember your
              user details for future correspondence.
            </Typography>
          </li>
          <li>
            <Typography style={{ fontWeight: 600 }}>
              Site preferences cookies
            </Typography>
            <Typography variant={"body1"}>
              In order to provide you with a great experience on this site we
              provide the functionality to set your preferences for how this
              site runs when you use it. In order to remember your preferences
              we need to set cookies so that this information can be called
              whenever you interact with a page is affected by your preferences.
            </Typography>
          </li>
        </ul>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          Third Party Cookies
        </Typography>

        <Typography variant={"body1"}>
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </Typography>

        <ul>
          <li>
            <Typography variant={"body1"}>
              This site uses Google Analytics which is one of the most
              widespread and trusted analytics solution on the web for helping
              us to understand how you use the site and ways that we can improve
              your experience. These cookies may track things such as how long
              you spend on the site and the pages that you visit so we can
              continue to produce engaging content.
              <br />
              <br />
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              Third party analytics are used to track and measure usage of this
              site so that we can continue to produce engaging content. These
              cookies may track things such as how long you spend on the site or
              pages you visit which helps us to understand how we can improve
              the site for you.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              As we sell products it's important for us to understand statistics
              about how many of the visitors to our site actually make a
              purchase and as such this is the kind of data that these cookies
              will track. This is important to you as it means that we can
              accurately make business predictions that allow us to monitor our
              advertising and product costs to ensure the best possible price.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              The Google AdSense service we use to serve advertising uses a
              DoubleClick cookie to serve more relevant ads across the web and
              limit the number of times that a given ad is shown to you.
              <br />
              <br />
              For more information on Google AdSense see the official Google
              AdSense privacy FAQ.
            </Typography>
          </li>

          <li>
            <Typography variant={"body1"}>
              We use adverts to offset the costs of running this site and
              provide funding for further development. The behavioural
              advertising cookies used by this site are designed to ensure that
              we provide you with the most relevant adverts where possible by
              anonymously tracking your interests and presenting similar things
              that may be of interest.
            </Typography>
          </li>
        </ul>

        <Typography style={{ marginTop: 60 }} variant={"h3"} color={"primary"}>
          More Information
        </Typography>
        <Typography variant={"body1"}>
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site.
          <br />
          <br />
          However if you are still looking for more information then you can
          contact us through one of our preferred contact methods:
        </Typography>
        <ul>
          <li>
            <Typography variant={"body1"}>
              Email: info@globalcustodiansolutions.com
            </Typography>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  )
}

export default CookieModal
